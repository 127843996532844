import { useState, useEffect } from 'react';
import axios from 'axios';

// components
import {
  Popover,
  Input,
  InputNumber,
  Skeleton,
  Button,
  Popconfirm
} from 'antd';
import Label from '../Label/Label';
// import Typewriter from 'react-typewriter-effect';
import Typewriter from 'typewriter-effect';

// assets
import { SettingOutlined, CopyOutlined } from "@ant-design/icons"

// context
import useAppState from '../../context/useAppState';
import useToast from '../../context/useToast'

const { TextArea } = Input;

const TableRow = ({
  sectionData,
  settingsSchema,
  section,
  i,
  onChangeSectionSettings,
  onRemoveSection,
  file,
  provided,
  snapshot,
  globalData,
  onDuplicate,
  getRequest,
  getUrl,
}) => {
  const [openSettings, setOpenSettings] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [response, setResponse] = useState("")
  const [isResponseLoading, setIsResponseLoading] = useState(false)

  const {
    onSignOut,
    initGenerateAll
  } = useAppState()

  const {
    messageApi
  } = useToast()

  useEffect(() => {
    if (initGenerateAll) {
      generateResponse()
    }
  }, [initGenerateAll])

  useEffect(() => {
    setResponse("")
    setIsResponseLoading(false)
  }, [file])

  const onEscapePress = (e) => {
    if (e.key === "Escape") setOpenSettings(false)
  }

  /**
  * Request to conversation endpoint
  */
  const generateResponse = () => {
    setIsResponseLoading(true)
    let request = getRequest(section)

    console.log({ request,  })

    axios.post(`${getUrl()}/conversation`, request, {
      // axios.post(`http://localhost:5000/conversation`, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("dev-xo-translator")}`
      }
    }).then(res => {
      if (res?.data?.status === "SUCCESS") {
        let response = res?.data?.response?.replace(/''/g, '');
        let prompt = res?.data?.prompt

        setResponse(response)
      } else if (res.data.status === "FAILED") {
        let resp = res?.data?.response
        messageApi.open({
          type: "error",
          duration: 5,
          content: resp
        })
      }
      setIsResponseLoading(false)
    }).catch(error => {
      if (error.response.status === 401) {
        onSignOut()
        messageApi.open({
          type: "error",
          duration: 5,
          content: "Session expired"
        })
      } else if (error.code === "ERR_NETWORK") {
        messageApi.open({
          type: "error",
          duration: 5,
          content: "503 Service unavailable."
        })
      } else if (error) {
        messageApi.open({
          type: "error",
          duration: 5,
          content: "An error occured."
        })
      }
      setIsResponseLoading(false)
    })
  }

  const settings = () => {
    return (
      <div
        className="flex flex-col gap-4"
        style={{
          width: 500
        }}>
        {settingsSchema.map(field => {
          if (field.type === "textarea") {
            return (
              <Label key={field.key} label={field.label}>
                <TextArea
                  rows={field.rows}
                  value={sectionData[i][field.key]}
                  onChange={e => onChangeSectionSettings(i, field.key, e.target.value)} />
              </Label>
            )
          } else if (field.type === "number") {
            return (
              <Label key={field.key} label={field.label}>
                <InputNumber
                  value={sectionData[i][field.key]}
                  onChange={e => onChangeSectionSettings(i, field.key, e)}
                  style={{ width: 200 }} />
              </Label>
            )
          }
        })}
        <div className="flex justify-between mt-6">
          <div className='flex gap-2'>
            <Popconfirm
              placement="topRight"
              title={"Remove section"}
              description={"Are you sure you want to remove this section?"}
              onConfirm={() => onRemoveSection(i)}
              okText="Remove"
              cancelText="Cancel"
              okButtonProps={{
                danger: true
              }}
            >
              <Button danger>
                - Remove section
              </Button>
            </Popconfirm>
            <Button
              onClick={() => console.log(getRequest(section))}>
              Log
            </Button>
          </div>
          <div className="flex gap-2">
            <Button
              onClick={() => setOpenSettings(false)}>
              Close
            </Button>
            <Button
              type={"primary"}
              loading={isResponseLoading}
              disabled={!file?.domain_id}
              onClick={() => {
                generateResponse()
                setOpenSettings(false)
              }}>
              Generate
            </Button>
          </div>
        </div>
      </div>
    )
  }

  /**
  * Renders response from conversation endpoint
  */
  const renderResponse = () => {
    if (isResponseLoading) {
      return (
        <Skeleton active title={false} paragraph />
      )
    } else if (globalData.showTypewriter) {
      if (response === "") return <></>
      return (
        <Typewriter
          options={{
            delay: globalData.typeSpeed,
          }}
          onInit={(typewriter) => {
            typewriter.typeString(response)
              .start();
          }}
        />
      )
    } else {
      return response
    }
  }

  let iconClassNames = ["action-icons"]
  if (showMenu) iconClassNames.push("active-icon")
  return (
    <div
      className='desc-row relative'
      key={section.key}
      onKeyDown={onEscapePress}
      onMouseEnter={() => setShowMenu(true)}
      onMouseLeave={() => setShowMenu(false)}>
      <div
        className="desc-col header-col"
        ref={provided.innerRef}
        snapshot={snapshot}
        {...provided.draggableProps}
        {...provided.dragHandleProps}>
        {i + 1}. {section.label}
      </div>
      <div className="desc-col" style={{ flexGrow: 2 }}>
        <div>
          <Popover
            open={openSettings}
            placement="left"
            title={`${i + 1}. Settings`}
            content={settings()}
            trigger="click">
            <SettingOutlined
              className={iconClassNames.join(" ")}
              style={{
                right: 5,
                top: 5,
              }}
              onClick={() => setOpenSettings(true)} />
          </Popover>
          <CopyOutlined
            className={iconClassNames.join(" ")}
            onClick={() => onDuplicate(section, i)}
            style={{
              right: 5,
              bottom: 5,
            }} />
          {renderResponse()}
        </div>
      </div>
    </div>
  )
};

export default TableRow;