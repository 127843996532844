export const modelOptions = [
  {
    value: "gpt-3.5-turbo",
    label: "GPT-3.5 turbo"
  },
  {
    value: "gpt-4",
    label: "GPT-4"
  }
]

export const sections = [
  {
    key: "first_page",
    label: "EU trial number and full trial title",
    promptHeader: "Act as a professional Clinical Trial Disclosure Specialist with decades of experience. Retrieve any information that is being asked regarding the study",
    userMessage: "Provide the EU trial number and full trial title",
    response: "",
  },
  {
    key: "rationale",
    label: "Rationale (Why is the study being done?)",
    promptHeader: "Act as a professional Clinical Trial Disclosure Specialist with decades of experience. Retrieve any information that is being asked regarding the study",
    userMessage: "Why is the study being done? (Rationale)",
    response: "",
  },
  {
    key: "objectives",
    label: "Objective and endpoints - primary and secondary (What are the objectives and endpoints of the study?)",
    promptHeader: "Act as a professional Clinical Trial Disclosure Specialist with decades of experience. Retrieve any information that is being asked regarding the study",
    userMessage: "What are the objectives and endpoints of the study? Include primary and/or secondary",
    response: "",
  },
  {
    key: "design",
    label: "Trial design (How is the study/trial designed?)",
    promptHeader: "Act as a professional Clinical Trial Disclosure Specialist with decades of experience. Retrieve any information that is being asked regarding the study",
    userMessage: "How is the study/trial designed?",
    response: "",
  },
  {
    key: "additional_info",
    label: "Additional information related to the Testing and Monitoring activities involved during the study",
    promptHeader: "Act as a professional Clinical Trial Disclosure Specialist with decades of experience. Retrieve any information that is being asked regarding the study",
    userMessage: "Provide additional information related to the Testing and Monitoring activities involved during the study",
    response: "",
  },
  {
    key: "population",
    label: "Trial population (Who can enroll in the study?)",
    promptHeader: "Act as a professional Clinical Trial Disclosure Specialist with decades of experience. Retrieve any information that is being asked regarding the study",
    userMessage: "Who can enroll in the study?",
    response: "",
  },
  {
    key: "interventions",
    label: "Interventions (How will the study be done?)",
    promptHeader: "Act as a professional Clinical Trial Disclosure Specialist with decades of experience. Retrieve any information that is being asked regarding the study",
    userMessage: "How will the study be done? (Interventions)",
    response: "",
  },
  {
    key: "risk_benefits",
    label: "Expected benefit and extent of burden and risks (What are the potential risks and benefits from enrolling in this study?)",
    promptHeader: "Act as a professional Clinical Trial Disclosure Specialist with decades of experience. Retrieve any information that is being asked regarding the study",
    userMessage: "What are the potential risks and benefits from enrolling in this study? (Expected benefit and extent of burden and risks)",
    response: "",
  },
]

export const globalSettingsSchema = [
  {
    type: "slider",
    key: "temperature",
    label: "Temperature",
    min: 0,
    max: 1,
    step: .1
  },
  {
    type: "switch",
    key: "showTypewriter",
    label: "Toggle typewriter"
  },
  {
    type: "slider",
    key: "typeSpeed",
    label: "Type speed (in milli seconds)",
    reverse: true,
    min: 0,
    max: 200,
    step: 5
  },
  {
    type: "select",
    key: "model",
    label: "Model",
    options: modelOptions
  },
  {
    type: "select",
    key: "api_url",
    label: "Api origin",
    options: [
      {
        value: "https://openai-api-xg.herokuapp.com",
        label: "Xogene"
      }
    ]
  },
]

export const settingsSchema = [
  {
    type: "textarea",
    key: "label",
    label: "Label"
  },
  {
    type: "textarea",
    key: "promptHeader",
    label: "Prompt header",
    rows: 6
  },
  {
    type: "textarea",
    key: "pineconePrompt",
    label: "Pinecone prompt",
    rows: 6
  },
  {
    type: "textarea",
    key: "userMessage",
    label: "Request message"
  }
]