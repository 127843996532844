import React from 'react';
import { Typography } from 'antd';
const { Text } = Typography;

const Label = ({ label, children }) => {
  return (
    <div className="flex flex-col">
      <Text className="mb-1" type="secondary">{label}</Text>
      {children}
    </div>
  );
};

export default Label;