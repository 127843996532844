import { useEffect } from 'react'

// components
import { Upload, Select, Button, Input, Space } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import AdobeEmbedSdkClient from '../../classes/AdobeEmbedSdkClient'

// context
import useAppState from '../../context/useAppState';

const { Dragger } = Upload;

const PdfReader = () => {
  const {
    file,
    setFile,
    files,
  } = useAppState()
  const divId = "pdf-div";
  const embedMode = "FULL_WINDOW"
  const defaultViewMode = "FIT_WIDTH"


  const embedWithURL = () => {
    let options = {
      embedMode,
      defaultViewMode,
      enableAnnotationAPIs: true,
      showAnnotationTools: true,
      enableLinearization: true, // show first page quickly
    }
    if (embedMode === 'FULL_WINDOW') {
      // annotations
      options.enableAnnotationAPIs = true
      options.includePDFAnnotations = true // displays existing annotations
      options.showDisabledSaveButton = true // if no changes
      // enableAnnotationEvents: true, // false by default

      // enableFormFilling: false, // true by default
    } else if (embedMode === 'IN_LINE') {
      options.showPageControls = false
    }

    const viewSDKClient = new AdobeEmbedSdkClient()
    viewSDKClient
      .ready()
      .then((res) => {
        /* By default the embed mode will be Full Window */
        viewSDKClient
          .previewFile(divId, {
            url: file.url,
            fileName: file.filename,
          }, options)
          .then((res) => {
            // not sure what TOGGLE_COMMENTING does, if anything:
            res.executeCommand('TOGGLE_COMMENTING', true)
              // .then(() => console.log('TOGGLE_COMMENTING SUCCESS'))
              .catch((error) => console.log('TOGGLE_COMMENTING', error))
          })
      })
  }

  const embedWithFile = () => {
    const viewSDKClient = new AdobeEmbedSdkClient()
    viewSDKClient.ready()
      .then(() => {
        const fileName = file.name
        const reader = new FileReader()
        reader.onloadend = (evt) => {
          const filePromise = Promise.resolve(evt.target.result)
          /* Helper function to render the file using PDF Embed API. */
          viewSDKClient.previewFileUsingFilePromise(divId, filePromise, fileName, {
            embedMode,
            defaultViewMode,
          })
        }
        reader.readAsArrayBuffer(file)
      })
  }

  useEffect(() => {
    if (file?.url) {
      embedWithURL()
    } else if (file) {
      embedWithFile()
    }
  }, [file])

  const onChangeFile = (fileId) => {
    let foundFile = files.find(item => item.id === fileId);
    setFile(foundFile)
  }

  const renderHeader = () => {
    return (
      <div className="flex lg:flex-row justify-between items-center mb-3">
        <img
          style={{
            width: 100,
            height: "auto"
          }}
          src={"https://www.xogene.com/wp-content/uploads/2018/10/Xogene_logo_color-2.png"}
          alt="xogene-logo" />
        <div>
          <Space.Compact>
            <Select
              value={file?.filename}
              onChange={onChangeFile}
              options={files.map(file => ({ value: file.id, label: file.filename }))}
              style={{ width: 300}}
            />
            {file && <Button
              type="primary"
              danger
              onClick={() => setFile(null)}
              icon={<CloseOutlined />}
            />}
          </Space.Compact>
        </div>
      </div>
    )
  }

  if (!file) {
    const props = {
      name: 'file',
      style: { flexGrow: 2 },
      onChange(info) {
        setFile(info.file.originFileObj)
      },
      onDrop(e) {
        setFile(e.dataTransfer.files[0])
      },
    };

    return (
      <div className="h-full flex flex-col">
        {renderHeader()}
        <Dragger {...props}>
          <div className='w-1/2 m-auto'>
            <p className="ant-upload-drag-icon">
              <PlusOutlined />
            </p>
            <p className="ant-upload-text">Click or drag PDF to this area to upload</p>
          </div>
        </Dragger>
      </div>
    )
  }

  return (
    <div className="flex flex-col h-full">
      {renderHeader()}
      <div id={divId} className="document-pdf h-full grow-1" />
    </div>

  );
};

export default PdfReader;