import { useState, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// components
import {
  Popover,
  Input,
  Slider,
  InputNumber,
  Typography,
  Button,
  Modal,
  Switch,
  Select
} from 'antd';
import Label from '../Label/Label';
import TableRow from './TableRow';

// context
import useAppState from '../../context/useAppState';

// assets
import { SettingOutlined } from "@ant-design/icons"

// constants
import { sections, globalSettingsSchema, settingsSchema } from '../../constants';

const { TextArea } = Input;
const { Title } = Typography;

const Synopsis = () => {
  const [globalData, setGlobalData] = useState({
    temperature: .2,
    showTypewriter: true,
    typeSpeed: 20
  })
  const [sectionData, setSectionData] = useState(sections);
  const [openModal, setOpenModal] = useState(false)
  const [disableAddSection, setDisableAddSection] = useState(true)
  const [addSectionData, setAddSectionData] = useState({
  })

  const {
    file,
    setInitGenerateAll
  } = useAppState()

  useEffect(() => {
    let lsGlobalData = localStorage.getItem("xo-plps-global-data-settings")
    let lsSectionData = localStorage.getItem("xo-plps-section-data-settings")

    if (lsGlobalData) setGlobalData(JSON.parse(lsGlobalData))
    if (lsSectionData) {
      let data = JSON.parse(lsSectionData)
      setSectionData(data)
    }
  }, [])

  useEffect(() => {
    let lsSectionData = localStorage.getItem("xo-plps-section-data-settings")
    if (lsSectionData) {
      let data = JSON.parse(lsSectionData)
      setSectionData(data?.map(section => ({
        ...section,
        response: ""
      })))
    }
  }, [file])

  /**
  * Returns formatted object for conversation endpoint.
  *
  * @param {string} str string with tabs/new lines/extra spaces
  * @return {string} trimmed string
  */
  const cleanString = (str) => {
    let cleanedString = str.replace(/[\n\t]/g, ' ');
    cleanedString = cleanedString.replace(/\s+/g, ' ');
    cleanedString = cleanedString.trim();

    return cleanedString;
  }

  /**
  * Returns formatted object for conversation endpoint.
  *
  * @param {object} section the object with section params.
  * @return {object} formatted object for api
  */
  const getRequest = (section) => {
    let domainId = file?.domain_id
    // let url = getUrl()

    return {
      model: globalData.model,
      domain_id: domainId,
      pinecone_index: file?.index ? file.index : "plps",
      promptHeader: section.promptHeader,
      conversationHistory: [],
      userMessage: cleanString(section.userMessage),
      temperature: globalData.temperature,
    }
  }

  /**
  * Logs all prompts
  */
  const logAllPrompts = () => {
    const requests = sectionData.map((section) => {
      return getRequest(section)
    });
    console.log(requests)
  }

  const getUrl = () => {
    let url = "https://openai-api-xg.herokuapp.com"
    // let url = "http://localhost:5000"

    if (globalData.api_url) url = globalData.api_url
    return url
  }

  const onChangeGlobalSettings = (key, value) => {
    let tempData = { ...globalData }
    tempData[key] = value
    setGlobalData(tempData)
    localStorage.setItem("xo-plps-global-data-settings", JSON.stringify(tempData))
  }

  const onChangeSectionSettings = (index, key, value) => {
    let tempData = [...sectionData]
    tempData[index][key] = value
    setSectionData(tempData)
    saveSectionData(tempData)
  }

  const globalSettings = () => {
    return (
      <div className="flex flex-col gap-4" style={{ width: 400 }}>
        {globalSettingsSchema.map(field => {
          if (field.type === "input") {
            return (
              <Label key={field.key} label={field.label}>
                <Input value={globalData[field.key]} onChange={e => onChangeGlobalSettings(field.key, e.target.value)} />
              </Label>
            )
          } else if (field.type === "slider") {
            return (
              <Label key={field.key} label={field.label}>
                <Slider
                  value={globalData[field.key]}
                  min={field.min}
                  max={field.max}
                  step={field.step}
                  reverse={field.reverse}
                  onChange={e => onChangeGlobalSettings(field.key, e)} />
              </Label>
            )
          } else if (field.type === "switch") {
            return (
              <Label key={field.key} label={field.label}>
                <Switch
                  style={{ width: 40 }}
                  checked={globalData[field.key]}
                  onChange={e => onChangeGlobalSettings(field.key, e)} />
              </Label>
            )
          } else if (field.type === "select") {
            return (
              <Label key={field.key} label={field.label}>
                <Select
                  style={{ width: 150 }}
                  value={globalData[field.key]}
                  onChange={e => onChangeGlobalSettings(field.key, e)}
                  options={field.options}
                />
              </Label>
            )
          }
        })}
      </div>
    )
  }

  const onChangeAddSectionData = (key, val) => {
    setAddSectionData({
      ...addSectionData,
      [key]: val
    })
  }

  useEffect(() => {
    const {
      label,
      promptHeader,
      userMessage,
    } = addSectionData

    if (!label || !promptHeader || !userMessage) {
      setDisableAddSection(true)
    } else if (disableAddSection === true) {
      setDisableAddSection(false)
    }
  }, [addSectionData])

  const onAddSection = () => {
    if (disableAddSection === false) {
      let tempData = [...sectionData, {
        key: uuid(),
        ...addSectionData,
      }]
      setSectionData(tempData)
      onCloseAddSection()
      saveSectionData(tempData)
    }
  }

  const onDuplicate = (section, i) => {

    let newSection = {
      ...section,
      key: uuid(),
      response: ""
    }

    let tempData = []

    sectionData.forEach((section, idx) => {
      if (i === idx) {
        tempData.push(section)
        tempData.push(newSection)
      } else {
        tempData.push(section)
      }
    })

    setSectionData(tempData)
    saveSectionData(tempData)
  }

  const onCloseAddSection = () => {
    setOpenModal(false)
    setAddSectionData({})
  }

  const onRemoveSection = (index) => {
    let tempData = [...sectionData]

    tempData.splice(index, 1)
    setSectionData(tempData)
    saveSectionData(tempData)
  }

  const onDragEnd = (result) => {
    const newItems = Array.from(sectionData);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setSectionData(newItems);
    saveSectionData(newItems)
  };

  const saveSectionData = (data) => {
    localStorage.setItem("xo-plps-section-data-settings", JSON.stringify(data.map(item => {
      return {
        ...item,
        response: ""
      }
    })))
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="relative">
        <Popover
          placement="leftTop"
          title={`Settings`}
          content={globalSettings()}
          trigger="click">
          <SettingOutlined
            style={{
              position: "absolute",
              right: 5,
              top: 5,
              fontSize: 12,
              color: "#636e72"
            }} />
        </Popover>
        <div>
          <Title
            className='pr-4'
            level={5}>Information regarding Plain Language Protocol Synopsis (PLPS) - {file?.protocol_id ? file.protocol_id : "(SELECT FILE)"}</Title>
        </div>
        <Droppable droppableId="drop-list">
          {(provided) => (
            <div
              className="desc-table"
              {...provided.droppableProps}
              ref={provided.innerRef}>
              {sectionData.map((section, i) => (
                <Draggable
                  key={section.key}
                  draggableId={`${i}-${section.key}`}
                  index={i}>
                  {(provided, snapshot) => (
                    <TableRow
                      onDuplicate={onDuplicate}
                      provided={provided}
                      snapshot={snapshot}
                      key={section.key}
                      sectionData={sectionData}
                      globalData={globalData}
                      settingsSchema={settingsSchema}
                      section={section}
                      onChangeSectionSettings={onChangeSectionSettings}
                      onRemoveSection={onRemoveSection}
                      file={file}
                      i={i}
                      getRequest={getRequest}
                      getUrl={getUrl} />

                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
      <div className="flex justify-between mt-6">
        <div className="flex gap-2">
          <Button
            onClick={() => setOpenModal(true)}>
            + Add section
          </Button>
          <Button
            onClick={() => logAllPrompts()}>
            Log all prompts
          </Button>
        </div>

        <Button
          type={"primary"}
          disabled={!file?.domain_id}
          onClick={() => setInitGenerateAll(true, () => setInitGenerateAll(false))}>
          Generate all
        </Button>
      </div>
      <Modal
        title="Add section"
        open={openModal}
        onOk={onAddSection}
        okButtonProps={{
          disabled: disableAddSection
        }}
        okText="Add section"
        onCancel={onCloseAddSection}>
        <div className="flex flex-col gap-4">
          {settingsSchema.map(field => {
            if (field.type === "textarea") {
              return (
                <Label key={field.key} label={field.label}>
                  <TextArea
                    rows={field.rows}
                    value={addSectionData[field.key]}
                    onChange={e => onChangeAddSectionData(field.key, e.target.value)} />
                </Label>
              )
            } else if (field.type === "number") {
              return (
                <Label key={field.key} label={field.label}>
                  <InputNumber
                    value={addSectionData[field.key]}
                    onChange={e => onChangeAddSectionData(field.key, e)}
                    style={{ width: 200 }} />
                </Label>
              )
            }
          })}
        </div>
      </Modal>
    </DragDropContext>
  );
};

export default Synopsis;