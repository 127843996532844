import { useState, useEffect } from 'react';
import axios from 'axios';

// components
import {
  Input,
  Divider,
  Typography,
  Select,
  Button,
  Skeleton,
  Space
} from 'antd';

// context
import useAppState from '../../context/useAppState';
import useToast from '../../context/useToast';

const { TextArea } = Input;
const { Title } = Typography

const langs = [
  "Arabic", "Bengali", "Bulgarian", "Chinese (Simplified)", "Croatian", "Czech", "Danish", "Dutch", "English", "Estonian", "Finnish", "French", "German", "Greek", "Hindi", "Hungarian", "Icelandic", "Irish", "Italian", "Japanese", "Korean", "Latvian", "Maltese", "Norwegian", "Polish", "Portuguese", "Punjabi", "Romania", "Slovak", "Serbian", "Slovenian", "Spanish", "Swedish", "Turkish"
]

const Translator = () => {
  const {
    accessToken,
    onSignOut
  } = useAppState()
  const {
    messageApi
  } = useToast()
  const [originalText, setOriginalText] = useState("")
  const [language, setLanguage] = useState(null)
  const [additionalInstructions, setAdditionalInstructions] = useState("")
  const [traslation, setTranslation] = useState("")
  const [loadingResp, setLoadingResp] = useState("")
  const [conversationHistory, setConversationHistory] = useState([])

  const cleanString = (inputString) => {
    let cleanedString = inputString.replace(/[\n\t]/g, ' ');
    cleanedString = cleanedString.replace(/\s+/g, ' ');
    cleanedString = cleanedString.trim();

    return cleanedString;
  }

  const onTranslate = () => {
    setLoadingResp(true)
    let userMessage = `Translate in ${language}: ${originalText}`

    if (additionalInstructions) {
      userMessage = `Translate in ${language} <ADDITIONAL INSTRUCTIONS>${additionalInstructions}</ADDITIONAL INSTRUCTIONS>: ${originalText}`
    }

    let request = {
      promptHeader: "Act as a professional translator decades of experience. Your client may give you <ADDITIONAL INSTRUCTIONS>",
      conversationHistory,
      userMessage: cleanString(userMessage)
    }

    axios.post("https://openai-api-xg.herokuapp.com/conversation", request, {
    // axios.post("http://localhost:5000/conversation", request, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then((res) => {
      if (res?.data?.status === "SUCCESS") {
        let response = res?.data?.response
        // let arr = [...conversationHistory, { userMessage, response }]
        // setConversationHistory(arr)
        setTranslation(response)
        setLoadingResp(false)
      } else if (res?.data?.status === "INVALID_TOKEN") {
        onSignOut()
        setLoadingResp(false)
      }
    })
      .catch(error => {
        if (error.response.status === 401) {
          onSignOut()
          messageApi.open({
            type: "error",
            duration: 5,
            content: "Session expired"
          })
        } else if (error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            duration: 5,
            content: "503 Service unavailable."
          })
        } else if (error) {
          messageApi.open({
            type: "error",
            duration: 5,
            content: "An error occured."
          })
        }
      })
  }

  return (
    <div className="flex flex-col h-full">
      <div className="h-full pb-8">
        <Title level={5}>Original</Title>
        <TextArea
          style={{ height: "100%" }}
          placeholder={originalText}
          onChange={e => setOriginalText(e.target.value)} />
      </div>
      <Divider />
      <div className="h-full flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <Title level={5} className="whitespace-nowrap" style={{ margin: 0 }}>Translation </Title>
          <Space.Compact style={{ display: "flex" }}>
            <Select
              showSearch
              placeholder="Select language"
              optionFilterProp="children"
              onChange={setLanguage}
              style={{ minWidth: 100 }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={langs.map(item => ({ value: item, label: item }))}
            />
            <Input
              value={additionalInstructions}
              onChange={e => setAdditionalInstructions(e.target.value)}
              placeholder="Additional instructions (Optional)" />
            <Button
              type="primary"
              disabled={!language}
              onClick={onTranslate}>
              Translate
            </Button>
          </Space.Compact>
        </div>
        {loadingResp ? (
          <Skeleton.Input
            active={true} />
        ) : (
          <TextArea
            style={{ height: "100%" }}
            value={traslation}
            onChange={e => { }} />
        )}
      </div>
    </div>
  );
};

export default Translator;