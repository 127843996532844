
// components
import PdfReader from './components/PdfReader/PdfReader';
import Translator from './components/Translator/Translator';
import { Layout } from 'antd';
import SignIn from './components/SignIn/SignIn';
import Synopsis from './components/Synopsis/Synopsis';
import List from './components/List/List';
import { Container, Section, Bar } from 'react-simple-resizer';

// context
import useAppState from './context/useAppState';
import useToast from './context/useToast';

const { Content } = Layout;

function App() {
  const { accessToken } = useAppState()
  const { contextHolder } = useToast()
  const contentStyle = {
    padding: 24,
    height: "100%",
    width: "100%"
  };

  if (!accessToken) return <SignIn />

  return (
    <Layout style={{ height: "100vh" }}>
      {contextHolder}
      <Content style={contentStyle}>
        <Container className="h-full w-full">
          <div className='flex gap-6 h-full w-full'>
            <Section className='w-full h-full bg-white p-3 relative'>
              <PdfReader />
            </Section>
            <Bar size={1} style={{ background: '#cdcdcd', cursor: 'col-resize' }} />
            <Section className='w-full h-full bg-white p-3 overflow-auto relative' style={{ overflowY: "auto" }}>
              <Synopsis />
            </Section>
          </div>
        </Container>
      </Content>
    </Layout>
  );
}

export default App;
