import React from 'react';
import ReactDOM from 'react-dom/client';
import 'antd/dist/reset.css';
import './index.scss';
import './styles/antd-overwrite.scss'
import App from './App';
import { AppStateProvider } from './context/useAppState';
import { ToastProvider } from './context/useToast';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppStateProvider>
    <ToastProvider>
      <App />
    </ToastProvider>
  </AppStateProvider>
);
