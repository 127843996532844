import axios from 'axios';
import { createContext, useContext, useState, useEffect } from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';

const AppStateContext = createContext({})

const dummyFiles = [
  {
    id: 4,
    domain_id: 34,
    protocol_id: "NL79504.068.21",
    filename: "D1_Protocol 2022-501559-99-00.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/D1_Protocol+2022-501559-99-00.pdf"
  },
  {
    id: 5,
    domain_id: 35,
    protocol_id: "NL81102.100.22",
    filename: "D1. Protocol [2022-501689-23-00].pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/D1.+Protocol+%5B2022-501689-23-00%5D.pdf"
  },
  {
    id: 6,
    domain_id: 36,
    protocol_id: "129Xe",
    filename: "Protocol 129Xe MR IPF_2022-502512-36-00.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/Protocol+129Xe+MR+IPF_2022-502512-36-00.pdf"
  },
  {
    id: 7,
    domain_id: 37,
    protocol_id: "Anti CD 20 in RMS",
    filename: "D1_Protocol_NoisyRebels_2022-502664-21-00.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/D1_Protocol_NoisyRebels_2022-502664-21-00.pdf"
  },
  {
    id: 8,
    domain_id: 38,
    protocol_id: "I5Q-MC-CGAR",
    filename: "Prot_I5Q-MC-CGAR.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/Prot_I5Q-MC-CGAR.pdf"
  },
  {
    id: 9,
    domain_id: 39,
    protocol_id: "I8B-FH-ITSE",
    filename: "Prot_I8B-FH-ITSE.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/Prot_I8B-FH-ITSE.pdf"
  },
  {
    id: 10,
    domain_id: 40,
    protocol_id: "I8F-MC-GPGI",
    filename: "Prot_I8F-MC-GPGI.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/Prot_I8F-MC-GPGI.pdf"
  },
  {
    id: 11,
    domain_id: 41,
    protocol_id: "I8F-MC-GPGM",
    filename: "Prot_I8F-MC-GPGM.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/Prot_I8F-MC-GPGM.pdf"
  },
  {
    id: 12,
    domain_id: 42,
    protocol_id: "J1B-MC-FRCF",
    filename: "Prot_J1B-MC-FRCF.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/Prot_J1B-MC-FRCF.pdf"
  },
  {
    id: 13,
    domain_id: 43,
    protocol_id: "SAP_026-03",
    filename: "Prot_SAP_026-03.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/Prot_SAP_026-03.pdf"
  },
  {
    id: 14,
    domain_id: 44,
    protocol_id: "SAP_031-01",
    filename: "Prot_SAP_031-01.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/Prot_SAP_031-01.pdf"
  },
  {
    id: 15,
    domain_id: 45,
    protocol_id: "SAP_402-05",
    filename: "Prot_SAP_402-05.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/Prot_SAP_402-05.pdf"
  },
  {
    id: 16,
    domain_id: 46,
    protocol_id: "SAP_Study 001-01",
    filename: "Prot_SAP_Study 001-01.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/Prot_SAP_Study+001-01.pdf"
  },
  {
    id: 17,
    domain_id: 47,
    protocol_id: "B1371005",
    filename: "Prot_B1371005.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/Prot_B1371005.pdf"
  },
  {
    id: 18,
    domain_id: 48,
    protocol_id: "B5381012",
    filename: "Prot_B5381012.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/Prot_B5381012.pdf"
  },
  {
    id: 19,
    domain_id: 49,
    protocol_id: "B9991033",
    filename: "Prot_B9991033.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/Prot_B9991033.pdf"
  },
  {
    id: 20,
    domain_id: 20,
    protocol_id: "C0801039",
    filename: "Prot_C0801039.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/Prot_C0801039.pdf"
  },
  {
    id: 21,
    domain_id: 21,
    protocol_id: "C3421005",
    filename: "Prot_C3421005.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/Prot_C3421005.pdf"
  },
  // {
  //   id: 50,
  //   domain_id: 50,
  //   protocol_id: "WO39760",
  //   filename: "Protocol Roche WO39760.pdf",
  //   url: "https://xogene-chatbot-assets.s3.amazonaws.com/Protocol+Roche+WO39760.pdf"
  // },
  {
    id: 51,
    domain_id: 51,
    protocol_id: "WO42133",
    filename: "Protocol Roche WO42133.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/Protocol+Roche+WO42133.pdf"
  },
  // {
  //   id: 52,
  //   domain_id: 52,
  //   protocol_id: "WP41047",
  //   filename: "Protocol Roche WP41047.pdf",
  //   url: "https://xogene-chatbot-assets.s3.amazonaws.com/Protocol+Roche+WP41047.pdf"
  // },
  {
    id: 53,
    domain_id: 53,
    protocol_id: "GS-US-223-1017",
    filename: "Protocol Gilead GS-US-223-1017.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/Protocol+Gilead+GS-US-223-1017.pdf"
  },
  {
    id: 54,
    domain_id: 54,
    protocol_id: "GS-US-342-5531",
    filename: "Protocol Gilead GS-US-342-5531.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/Protocol+Gilead+GS-US-342-5531.pdf"
  },
  {
    id: 55,
    domain_id: 55,
    protocol_id: "GS-US-417-5937",
    filename: "Protocol Gilead GS-US-417-5937.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/Protocol+Gilead+GS-US-417-5937.pdf"
  },
  // {
  //   id: 56,
  //   domain_id: 56,
  //   protocol_id: "CACZ885D2310",
  //   filename: "Protocol_Novartis_CACZ885D2310.pdf",
  //   url: "https://xogene-chatbot-assets.s3.amazonaws.com/Protocol_Novartis_CACZ885D2310.pdf"
  // },
  // {
  //   id: 57,
  //   domain_id: 57,
  //   protocol_id: "CCTL019BUS03",
  //   filename: "Protocol_Novartis_CCTL019BUS03.pdf ",
  //   url: "https://xogene-chatbot-assets.s3.amazonaws.com/Protocol_Novartis_CCTL019BUS03.pdf"
  // },
  {
    id: 58,
    domain_id: 58,
    protocol_id: "CRTH258A2308",
    filename: "Protocol_Novartis_CRTH258A2308.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/Protocol_Novartis_CRTH258A2308.pdf"
  },


  {
    id: 60,
    domain_id: 60,
    protocol_id: "NL79504.068.21",
    filename: "[P] D1_Protocol 2022-501559-99-00.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/D1_Protocol+2022-501559-99-00.pdf",
    index: "sample-protocol"
  },
  {
    id: 61,
    domain_id: 61,
    protocol_id: "Anti CD 20 in RMS",
    filename: "[P] D1_Protocol_NoisyRebels_2022-502664-21-00.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/D1_Protocol_NoisyRebels_2022-502664-21-00.pdf",
    index: "sample-protocol"
  },
  {
    id: 62,
    domain_id: 62,
    protocol_id: "NL81102.100.22",
    filename: "[P] D1. Protocol [2022-501689-23-00].pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/D1.+Protocol+%5B2022-501689-23-00%5D.pdf",
    index: "sample-protocol"
  },

  {
    id: 63,
    domain_id: 63,
    protocol_id: "B1371005",
    filename: "[P] Prot_B1371005.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/Prot_B1371005.pdf",
    index: "sample-protocol"
  },
  {
    id: 64,
    domain_id: 64,
    protocol_id: "B5381012",
    filename: "[P] Prot_B5381012.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/Prot_B5381012.pdf",
    index: "sample-protocol"
  },
  {
    id: 65,
    domain_id: 65,
    protocol_id: "B9991033",
    filename: "[P] Prot_B9991033.pdf",
    url: "https://xogene-chatbot-assets.s3.amazonaws.com/Prot_B9991033.pdf",
    index: "sample-protocol"
  },
]

export const AppStateProvider = ({ children }) => {
  const [files, setFiles] = useState(dummyFiles)
  const [file, setFile] = useState(null)
  const [isAuthLoading, setIsAuthLoading] = useState(false)
  const [accessToken, setAccessToken] = useState(null)
  const [loginError, setLoginError] = useState(false)
  const [initGenerateAll, setInitGenerateAll] = useStateWithCallbackLazy(false)

  useEffect(() => {
    let token = localStorage.getItem("dev-xo-translator")
    if (token && token !== "undefined") {
      setAccessToken(token)
    }
  }, [])

  const onLogin = async ({ username, password }) => {
    setIsAuthLoading(true)
    let resp = await axios.post("https://openai-api-xg.herokuapp.com/signin", {
    // let resp = await axios.post("http://localhost:5000/signin", {
      username,
      password
    }).catch((err) => {
      console.log(err)
    })

    if (resp?.data?.token && resp?.data?.status === "SUCCESS") {
      localStorage.setItem("dev-xo-translator", resp.data.token)
      setAccessToken(resp.data.token)
      setLoginError(false)
    } else {
      setLoginError(true)
    }

    setIsAuthLoading(false)
  }

  const onSignOut = () => {
    setLoginError(false)
    setAccessToken(null)
    setIsAuthLoading(false)
    setFile(null)
    localStorage.setItem("dev-xo-translator", null)
  }

  return (
    <AppStateContext.Provider value={{
      isAuthLoading,
      setIsAuthLoading,
      file,
      setFile,
      accessToken,
      loginError,
      onLogin,
      onSignOut,
      files,
      setFiles,
      initGenerateAll, 
      setInitGenerateAll
    }}>
      {children}
    </AppStateContext.Provider>
  )
}

const useAppState = () => {
  return useContext(AppStateContext);
};

export default useAppState;