import React, { useState } from 'react';

// context
import useAppState from '../../context/useAppState';

// styles
import './SignIn.scss';

const SignIn = () => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const { onLogin, loginError, isAuthLoading } = useAppState()

  return (
    <div className="flex flex-col justify-center items-center gap-5 h-screen w-full chatbot-login-container">
      <input type="text" autoFocus placeholder="Username" disabled={isAuthLoading} onChange={e => setUsername(e.target.value)}/>
      <input type="password" placeholder="Password" disabled={isAuthLoading} onChange={e => setPassword(e.target.value)}/>
      <button onClick={() => onLogin({ username, password })} disabled={isAuthLoading}>Sign in</button>
      {loginError && <p className="mt-4 text-red-500">Invalid credentials</p>}
    </div>
  );
};

export default SignIn;