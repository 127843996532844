/* Code taken from https://github.com/adobe/pdf-embed-api-samples/blob/master/More%20Samples/React%20Samples/src/samples/ViewSDKClient.js and modified */
import { v4 as uuidv4 } from 'uuid'
// import fileDownload from '../utilities/fileDownload' // TODO: remove - only for testing

const clientId = process.env.REACT_APP_ADOBE_EMBED_CLIENT_ID

class AdobeEmbedSdkClient {
  constructor() {
    this.readyPromise = new Promise((resolve) => {
      if (window.AdobeDC) {
        resolve()
      } else {
        /* Wait for Adobe Document Services PDF Embed API to be ready */
        document.addEventListener("adobe_dc_view_sdk.ready", () => {
          resolve()
        })
      }
    })
    this.adobeDCView = undefined
  }

  ready() {
    return this.readyPromise
  }

  registerProfile({
    name,
    firstName,
    lastName,
    email,
  }) {
    const profile = {
      userProfile: {
        name,
        firstName,
        lastName,
        email,
      }
    }
    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.GET_USER_PROFILE_API,
      () => {
        return new Promise((resolve, reject) => {
          resolve({
            code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
            data: profile
          })
        })
      }, {})
  }

  previewFile(divId, { url, fileName }, viewerConfig) {
    const config = {
      /* Pass your registered client id */
      clientId,
      /* locale code */
      // locale: "es-ES",
    }
    if (divId) { /* Optional only for Light Box embed mode */
      /* Pass the div id in which PDF should be rendered */
      config.divId = divId
    }
    /* Initialize the AdobeDC View object */
    this.adobeDCView = new window.AdobeDC.View(config)

    /* Invoke the file preview API on Adobe DC View object */
    const previewFilePromise = this.adobeDCView.previewFile({
      /* Pass information on how to access the file */
      content: {
        /* Location of file where it is hosted */
        location: {
          url,
          /*
          If the file URL requires some additional headers, then it can be passed as follows:-
          headers: [
            {
              key: "<HEADER_KEY>",
              value: "<HEADER_VALUE>",
            }
          ]
          */
        },
      },
      /* Pass meta data of file */
      metaData: {
        /* file name */
        fileName: fileName ? fileName : " ",
        /* file ID */
        // TODO: save file ID to DB and use it instead.
        id: uuidv4(),
        // id: "6d07d124-ac85-43b3-a867-36930f502ac6",
      }
    }, viewerConfig)

    // previewFilePromise.then(adobeViewer => {
    //   adobeViewer.getAPIs().then((apis) => {
    //     // All viewer APIs can be invoked here
    //   });
    // })
    return previewFilePromise
  }

  previewFileUsingFilePromise(divId, filePromise, fileName, viewerConfig = {}) {
    /* Initialize the AdobeDC View object */
    this.adobeDCView = new window.AdobeDC.View({
      /* Pass your registered client id */
      clientId,
      /* Pass the div id in which PDF should be rendered */
      divId,
    })

    /* Invoke the file preview API on Adobe DC View object */
    this.adobeDCView.previewFile({
      /* Pass information on how to access the file */
      content: {
        /* pass file promise which resolve to arrayBuffer */
        promise: filePromise,
      },
      /* Pass meta data of file */
      metaData: {
        /* file name */
        fileName,
      }
    }, viewerConfig)
  }

  registerSaveApiHandler({
    name,
    onSave,
    documentData, // data needed to save PDF with updatad comments
  }) {
    const saveOptions = {
      // autoSaveFrequency: <Number, default=0>,
      // enableFocusPolling: <Boolean, default=false>,
      // showSaveButton: <Boolean, default=true>
    }
    /* Define Save API Handler */
    const saveApiHandler = (metaData, content, options) => {
      // console.log('saveApiHandler', {
      //   metaData, content, options,
      // })

      const blob = new Blob([content], { type: "application/pdf" })
      const file = new File([blob], name, {
        type: metaData?.mimeType,
      })

      return new Promise((resolve, reject) => {

        onSave(file, documentData)
          .then(() => {
            const response = {
              code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
              data: {
                metaData: Object.assign(metaData, { updatedAt: new Date().getTime() }),
              },
            }
            resolve(response)
          })
      })

      /* Save callback failure case */
      /* return new Promise((resolve, reject) => {
        reject({
          code: AdobeDC.View.Enum.ApiResponseCode.FAIL,
          data: {
            // <Optional>
          }
        })
      }) */

      /* Save callback file modified case */
      /* return new Promise((resolve, reject) => {
        resolve({
          code: AdobeDC.View.Enum.ApiResponseCode.FILE_MODIFIED,
          data: {
            modifiedBy: {
              // name: <Username of the user who modified the file>,
              // mail: <Email of the user who modified the file>,
            }
          }
        })
      }) */
    }

    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.SAVE_API,
      saveApiHandler,
      saveOptions,
    )
  }

  /* The status callback allows you to poll the status of the file to check whether the currently open file is changed by another user or not. This file status polling occurs after a fixed interval of time defined in the filePollFrequency option. Use both the save and status callbacks together to achieve the complete save experience including auto-save and file status polling. */
  registerStatusApiHandler() {
    const statusOptions = {
      // filePollFrequency: <Number, default=10>,
      // keepPolling: <Boolean, default=false>
    }
    this.adobeDCView.registerCallback(
      this.AdobeDC.View.Enum.CallbackType.STATUS_API,
      (metaData) => {
        /* Resolve or reject response in given format */
        return new Promise((resolve, reject) => {
          resolve({
            code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
            data: {} // optional
          })
        })

        /* Status callback file modified case */
        /* return new Promise((resolve, reject) => {
          resolve({
            code: AdobeDC.View.Enum.ApiResponseCode.FILE_MODIFIED,
            data: {
              modifiedBy: {
                //  name: <Username of the user who modified the file>,
                //  mail: <Email of the user who modified the file>,
              }
            }
          })
        }) */
      },
      statusOptions
    )
  }

  registerEventsHandler() {
    /* Register the callback to receive the events */
    this.adobeDCView.registerCallback(
      /* Type of call back */
      window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
      /* call back function */
      (event) => {
        console.log(event)
      },
      /* options to control the callback execution */
      {
        /* Enable PDF analytics events on user interaction. */
        enablePDFAnalytics: true,
      }
    )
  }
}

export default AdobeEmbedSdkClient
