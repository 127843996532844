import { useContext, createContext } from 'react'
import { Button, message, Space } from 'antd';

const ToastContext = createContext()

export const ToastProvider = ({ children }) => {
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <ToastContext.Provider
      value={{
        messageApi,
        contextHolder
      }}>
      {children}
    </ToastContext.Provider>
  )
}

const useToast = () => {
  return useContext(ToastContext)
}

export default useToast;